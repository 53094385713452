body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-wrapper {
  transition-timing-function: linear;
}

/** Animated Background */

.background_slide {
  animation-name: slide;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes slide {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1920px;
  }
}

.background_spin {
  animation-name: spin;
  animation-duration: 80s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
